<template>
  <div class="contract-code-container">
    <div
      style="margin: 2.75rem 0 3.125rem 0;"
      class="col-center"
    >
      <p class="contract-code-title">智 能 合 约 代 码</p>
      <div class="code-container">
        <p class="code-tip">import { Action } from "gchain-ts-lib/src/action</p>
        <p class="code-tip">import { Contract } from "gchain-ts-lib/src/contract";</p>
        <p class="code-tip">import { env as action } from "gchain-ts-lib/internal/action.d";</p>
        <p class="code-tip">import { Asset, StringToSymbol } from "gchain-ts-lib/src/asset";</p>
        <p class="code-tip">import { Log } from "gchain-ts-lib/src/log";</p>
        <p class="code-tip">import { NAME, Account, RNAME } from "gchain-ts-lib/src/account";</p>
        <p class="code-tip">import { UIP09 } from "gchain-ts-lib/uips/uip09";</p>
        <p class="code-tip">class NftAccount implements Serializable {</p>
        <p class="code-tip1">balance: Asset;</p>
        <p class="code-tip1">token_ids: Array; // Current account token ids</p>
        <p class="code-tip1">constructor(blc: Asset) {</p>
        <p class="code-tip1">this.token_ids = new Array();</p>
        <p class="code-tip1">this.balance = blc;</p>
        <p class="code-tip1">primaryKey(): id_type { return this.balance.symbolName(); }</p>
        <p class="code-tip">}</p>
        <p class="code-tip">class CurrencyStats implements Serializable {</p>
        <p class="code-tip2">supply: Asset;</p>
        <p class="code-tip2">max_supply: Asset;</p>
        <p class="code-tip2">issuer: account_name;</p>
        <p class="code-tip2">name: string;</p>
        <p class="code-tip2">memo: string;</p>
        <p class="code-tip">constructor() {</p>
        <p class="code-tip2"> this.max_supply = new Asset();</p>
        <p class="code-tip2"> this.supply = new Asset();</p>
        <p class="code-tip2"> this.issuer = 0;</p>
        <p class="code-tip2"> this.name = "";</p>
        <p class="code-tip2"> this.memo = "";</p>
        <p class="code-tip">}</p>
        <p class="code-tip2">primaryKey(): id_type { return this.supply.symbolName(); }</p>
        <p class="code-tip2">// deserialize(ds: DataStream): void {</p>
        <p class="code-tip2">// this.supply.deserialize(ds);</p>
        <p class="code-tip2">// this.max_supply.deserialize(ds);</p>
        <p class="code-tip2">// this.issuer = ds.read();</p>
        <p class="code-tip1">}</p>
        <p class="code-tip1">// serialize(ds: DataStream): void {</p>
        <p class="code-tip1"> // this.supply.serialize(ds);</p>
        <p class="code-tip1">// this.max_supply.serialize(ds);</p>
        <p class="code-tip1">// ds.write(this.issuer);</p>
        <p class="code-tip1">}</p>
        <p class="code-tip">}</p>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      code: `import { Action } from "gchain-ts-lib/src/action";
import { Contract } from "gchain-ts-lib/src/contract";
import { env as action } from "gchain-ts-lib/internal/action.d";
import { Asset, StringToSymbol } from "gchain-ts-lib/src/asset";
import { Log } from "gchain-ts-lib/src/log";
import { NAME, Account, RNAME } from "gchain-ts-lib/src/account";
import { UIP09 } from "gchain-ts-lib/uips/uip09";
class NftAccount implements Serializable {
  balance: Asset;
  token_ids: Array; // Current account token ids
  constructor(blc: Asset) {
    this.token_ids = new Array();
    this.balance = blc;
  }
  primaryKey(): id_type { return this.balance.symbolName(); }
}
class CurrencyStats implements Serializable {
  supply: Asset;
  max_supply: Asset;
  issuer: account_name;
  name: string;
  memo: string;
  constructor() {
    this.max_supply = new Asset();
    this.supply = new Asset();
    this.issuer = 0;
    this.name = "";
    this.memo = "";
  }
  primaryKey(): id_type { return this.supply.symbolName(); }
  // deserialize(ds: DataStream): void {
  //     this.supply.deserialize(ds);
  //     this.max_supply.deserialize(ds);
  //     this.issuer = ds.read();
  // }
  // serialize(ds: DataStream): void {
  //     this.supply.serialize(ds);
  //     this.max_supply.serialize(ds);
  //     ds.write(this.issuer);
  // }
}
`
    }
  },
  created () {
    this.$store.commit('HIDE_APPLOADING')
  }
}
</script>